import React from 'react'

import { Input, InputProfession, useNotify } from '@azos/shared'
import { Utils as B2CUtils } from '@data/utils'
import { Profession as ProfessionModel } from '@domain/models'
import Fade from '@mui/material/Fade'
import Grid from '@mui/material/Grid'
import { Subtitle } from '@presentation/components'
import { useProfessions } from '@presentation/hooks/useProfessions'
import { useFormikContext } from 'formik'

import { Container } from '../../containers'
import { SimulationValue } from '../../Simulation.props'
import { ProfessionProps } from './Profession.props'

const ACTIONS = {
  INPUT_PROFESSION_AUTOCOMPLETE:
    B2CUtils.actions.simulation.INPUT_PROFESSION_AUTOCOMPLETE,
}

const Profession = ({ context }: ProfessionProps) => {
  const { isTransition } = context

  const { addNotify } = useNotify()

  const {
    values,
    errors,
    setFieldValue,
    isSubmitting,
    handleChange,
    handleBlur,
  } = useFormikContext<SimulationValue>()

  const { data: professions, isLoading, isError } = useProfessions()

  const [professionValue, setProfessionValue] = React.useState<string>('')

  const professionsOptions = React.useMemo<string[]>(
    () =>
      professions
        ?.filter(item => !item.requiresDescription)
        .map(item => item.name) || [],
    [professions],
  )

  const isDisabled = React.useMemo<boolean>(
    () => isLoading || isSubmitting || isTransition,
    [isLoading, isSubmitting, isTransition],
  )

  const noOptionText = React.useMemo<string>(
    () =>
      professions?.find(item => item.requiresDescription)?.name ||
      'Não encontrado',
    [professions],
  )

  const requiredDescription = React.useMemo<boolean>(
    () =>
      professions?.find(item => item.professionId === values.profession?.id)
        ?.requiresDescription || false,
    [professions, values.profession?.id],
  )
  const selectProfession = React.useCallback(
    (profession: ProfessionModel, description = '') => {
      const { professionId, name, requiresDescription } = profession
      setProfessionValue(name)
      setFieldValue(
        'profession',
        { id: professionId, description, requiresDescription, name },
        !requiresDescription,
      )
    },
    [setFieldValue],
  )

  const handleProfessionChange = React.useCallback(
    (_event: React.ChangeEvent<any>, data = '') => {
      setProfessionValue(data)

      const profession = professions?.find(item => item.name === data)

      if (profession) {
        selectProfession(profession)
      }
    },
    [professions, selectProfession],
  )

  React.useEffect(() => {
    if (
      !professionValue &&
      !!values.profession?.id &&
      !!professions &&
      professions?.length > 0
    ) {
      const profession = professions?.find(
        item => item.professionId === values.profession?.id,
      )

      if (profession) {
        selectProfession(profession, values.profession?.description)
      }
    }
  }, [
    professionValue,
    professions,
    selectProfession,
    values.profession?.description,
    values.profession?.id,
  ])

  React.useEffect(() => {
    if (!!professionValue && !requiredDescription) {
      setFieldValue('profession.description', '')
    }
  }, [professionValue, requiredDescription, setFieldValue])

  React.useEffect(() => {
    if (isError) {
      addNotify({
        message: 'Houve um erro ao carregar as profisssões',
        status: 'error',
      })
    }
  }, [addNotify, isError])

  return (
    <Container>
      <Subtitle>Conta pra gente: qual é a sua profissão atual?</Subtitle>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <InputProfession
            parentName={ACTIONS.INPUT_PROFESSION_AUTOCOMPLETE}
            loading={isLoading}
            options={professionsOptions}
            value={professionValue}
            onChange={handleProfessionChange}
            noOptionText={noOptionText}
            error={!!errors.profession?.id}
            helperText={errors.profession?.id}
            disabled={isDisabled}
            fullWidth
          />
        </Grid>

        <Fade in={requiredDescription} unmountOnExit>
          <Grid item xs={12}>
            <Input
              autoFocus
              name="profession.description"
              label="Especifique"
              value={values.profession?.description || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isDisabled}
              error={!!errors.profession?.description}
              helperText={errors.profession?.description}
              fullWidth
            />
          </Grid>
        </Fade>
      </Grid>
    </Container>
  )
}

export default Profession
