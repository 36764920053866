import React from 'react'

import { RadioButton, RadioButtonData, RadioButtonOptions } from '@azos/shared'
import { Utils as B2CUtils } from '@data/utils'
import { salaries } from '@main/config/averages'
import { Subtitle } from '@presentation/components'
import { useFormikContext } from 'formik'

import { Container } from '../../containers'
import { SimulationValue } from '../../Simulation.props'
import { SalaryProps } from './Salary.props'

const ACTIONS = {
  BUTTON_SALARY: B2CUtils.actions.simulation.BUTTON_SALARY,
}

const Salary = ({ context }: SalaryProps) => {
  const { isTransition } = context

  const { values, errors, setFieldValue, submitForm, isSubmitting } =
    useFormikContext<SimulationValue>()

  const isDisabled = React.useMemo<boolean>(
    () => isSubmitting || isTransition,
    [isSubmitting, isTransition],
  )

  const options = React.useMemo<RadioButtonOptions[]>(
    () =>
      salaries
        .sort((a, b) => (a.amount <= b.amount ? 1 : -1))
        .map(item => ({
          value: item.amount,
          label: item.label,
        })),
    [],
  )

  const handleSelect = React.useCallback(
    async (value: RadioButtonData) => {
      const salaryOption = salaries.find(item => item.amount === value)
      const salaryValue = salaryOption?.amount
      setFieldValue('salary', salaryValue, true)

      submitForm()
    },
    [setFieldValue, submitForm],
  )

  return (
    <Container controlProps={{ hideNextButton: true }}>
      <Subtitle>E qual é a sua renda mensal?</Subtitle>

      <RadioButton
        name="salary"
        columns="flex"
        options={options}
        value={values.salary}
        onChange={handleSelect}
        error={!!errors.salary}
        helperText={errors.salary}
        disabled={isDisabled}
        parentName={ACTIONS.BUTTON_SALARY}
      />
    </Container>
  )
}

export default Salary
